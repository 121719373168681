










































































































































import { Component, Vue, ProvideReactive } from 'vue-property-decorator';
import { getPublishFormTree, saveFormData } from '@/api/form-design/FormApi';
import DocumentApi from '@/api/document/DocumentApi';
import DocumentForm from '@/views/document/document-form.vue';
import { getValue } from '@/utils/commonUtil';
import { deleteFileItem } from '@/api/main/MainAPi';
interface params {
  contentModelCode: string | undefined;
  dataInstanceId: string;
  formId: string;
}
@Component({
  name: 'DocumentList',
  components: {
    DocumentForm,
    FormViewer: () => import('@/components/form-design/viewer/form-viewer.vue')
  }
})
export default class DocumentList extends Vue {
  @ProvideReactive() formId: any = null;
  @ProvideReactive() formDataId: any = null;

  // 表单搜索
  formSearchModel: any = { name: '' };
  // 应用树列表
  formTreeData: any[] = [];
  // 表格数据
  tableListData: any[] = [];
  // 分页参数
  pageIndex: number = 1;
  // 每页大小
  pageSize: number = 10;
  // 总条数
  total: number = 0;
  // 当前页
  currentPage: number = 1;
  // tab默认选中
  activeName: string = 'first';
  // 选中的目录树
  catalogueId: string = '';
  // 是否显示新增编辑弹框
  isShowAddEdit: boolean = false;
  // 是否显示预览弹框
  formDialogVisible: boolean = false;
  // 是新增还是编辑
  isAddOrEdit: string = '';
  // 当前选中的行的文档id
  var_current_archives_id: string = '';
  type: string | string[] = '';

  displayName: string = '';
  displayParentName: string = '';
  successCreatDocumentForm: boolean = false;
  created() {
    this.initData();
    this.type = this.$route.query.type;
    this.getLeftTree(this.type);
  }

  //根据路由-初始化数据
  initData() {
    if (this.$route.query && this.$route.query.catalogueId) {
      // 如果有 catalogueId 就显示查看
      this.activeName = 'second';
      this.catalogueId = this.$route.query.catalogueId as string;
      this.getFilePage();
    }
  }
  changeParentDialogName(val) {
    this.displayParentName = val;
  }
  /**
   * 获取树列表
   */
  getLeftTree(type) {
    DocumentApi.userCanCreateTree().then((res: Record<any, any>) => {
      if (res.code == '1') {
        this.formTreeData = [{ id: '-1', name: this.$t('lang_top_node'), children: res.data }];
      } else {
        this.$message.error(res.message);
      }
    });
  }

  getSpeTime(timeStr) {
    // timeStr是毫秒值
    // 获取年份
    var year = new Date(timeStr).getFullYear();
    //获取月份，获取的月份比实际小1，所以需要+1
    var month = new Date(timeStr).getMonth() + 1;
    //获取日
    var date = new Date(timeStr).getDate();
    //组合格式为年-月-日 时：分：秒（2022-6-6）
    return year + '-' + month + '-' + date;
  }

  /**
   * 右侧table列表
   */
  getFilePage() {
    let params = {
      types: ['2', '3'],
      catalogueId: this.catalogueId,
      objectName: this.formSearchModel.name,
      page: {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      }
    };
    DocumentApi['myPublishPage'](params).then((res: Record<any, any>) => {
      if (res.code == '1') {
        this.tableListData = res.data.list;
        this.total = res.data.total;
      } else {
        this.$message.error(res.message);
      }
    });
  }

  /**
   * 编辑文档
   */
  editFile(row) {
    // console.log(row);
    if (row.arguments && row.arguments.length) {
      this.formId = getValue(row.arguments, 'formId');
      this.isAddOrEdit = 'edit';
      this.formDataId = getValue(row.arguments, 'dataInstanceId');
      this.var_current_archives_id = row.id;
      this.formDialogVisible = true;
    }
  }
  // 删除文档
  deleteFile(row: any) {
    let params = {} as params;
    if (row.arguments && row.arguments.length) {
      row.arguments.forEach((item: any, index: any) => {
        params[item.name] = item.value;
      });

      deleteFileItem({ formDataId: params.dataInstanceId, formId: params.formId }).then((reg: any) => {
        console.log(reg);
        if (reg.code == 1) {
          this.$message.success(reg.message);
          this.getFilePage();
        } else {
          this.$message.error(reg.message);
        }
      });
    }
  }
  /**
   * 删除文件
   */
  removeFile(row) {
    this.$confirm(this.$t('lang_whether_or_not_to_delete') as string, this.$t('lang_tips') as string, {
      confirmButtonText: this.$t('lang_determine_') as string,
      cancelButtonText: this.$t('lang_cancel_') as string,
      type: 'warning'
    })
      .then(() => {
        let params = [row.id];
        DocumentApi.delete(params)
          .then((res: Record<any, any>) => {
            if (res.code == '1') {
              this.getFilePage();
            } else {
              this.$message.error(res.message);
            }
          })
          .finally(() => {});
      })
      .catch(() => {});
  }

  /**
   * tab切换
   */
  handleClick(tab) {
    this.tableListData = [];
    this.pageIndex = 1;
    this.getLeftTree(this.$route.query.type);
  }

  /**
   * 点击新建
   */
  addFile(row?: any) {
    if (!this.catalogueId) return this.$message.warning(this.$t('lang_please_select_a_file') as any);
    this.isAddOrEdit = 'add';
    this.isShowAddEdit = true;
  }

  /**
   * 关闭新增弹框
   */
  closeDiaolog(data) {
    this.formId = data.formId;
    this.formDataId = null;
    this.successCreatDocumentForm = true;
    this.formDialogVisible = true;
  }

  handleCloseDialog() {
    this.successCreatDocumentForm = false;
    this.formDialogVisible = false;
  }

  /**
   * 搜索
   */
  handleSearch() {
    this.getFilePage();
  }

  /**
   * 改变分页大小
   */
  changePageSize(pageSize: number) {
    if (pageSize < 1) {
      this.pageSize = 1;
    } else if (pageSize >= this.total) {
      this.pageSize = this.total;
    } else {
      this.pageSize = pageSize;
    }
    this.getFilePage();
  }

  /**
   * 跳转至某页
   */
  changePage(page: number) {
    let totalPage = this.total / this.pageSize;
    let remainder = this.total % this.pageSize;
    if (remainder != 0) {
      totalPage++;
    }

    if (page < 1) {
      this.pageIndex = 1;
    } else if (page > totalPage) {
      this.pageIndex = totalPage;
    } else {
      this.pageIndex = page;
    }
    this.getFilePage();
  }

  /**
   * 应用树节点点击事件
   * @param data
   */
  clickTreeNode(data) {
    this.displayName = data.displayName;
    this.catalogueId = data.id;
    this.getFilePage();
  }

  /**
   * description  提交
   * @param {}
   * @returns {}
   */
  handleSave() {
    (this.$refs.formViewerRef as any).getFormDataForSave().then(result => {
      result.argument = {
        var_current_catalogue_id: this.catalogueId
      };
      // 编辑传当前行的文档id
      if (this.isAddOrEdit == 'edit') {
        // 添加日志
        this.logSave(this.var_current_archives_id, '4');
        result.argument.var_current_archives_id = this.var_current_archives_id;
      }
      saveFormData(result).then((res: Record<string, any>) => {
        if (res.code == '1') {
          this.$message.success(res.message);
          this.formDialogVisible = false;
          this.getFilePage();
        }
      });
    });
  }

  /**
   * 编辑，查看，下载，调保存日志
   * @params{ type } E: 编辑; R: 查看；D: 下载；P: 打印
   */
  logSave(id, type) {
    let params = {
      archivesId: id,
      opType: type
    };
    DocumentApi.logSave(params)
      .then((res: Record<any, any>) => {})
      .catch(e => {
        this.$message.error(e);
      });
  }

  /**
   * 跳转到文档详情
   */
  jumpEdit(row) {
    let path = this.$router.resolve({
      path: '/detail',
      query: { archivesId: row.id }
    });
    window.open(path.href, '_blank');
  }
}
