var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { height: "100%" } },
    [
      _c(
        "glb-spliter",
        {
          staticStyle: { height: "100%" },
          attrs: {
            gutter: 1,
            spanList: [
              { span: 5, slot: "left" },
              { span: 19, slot: "right" },
            ],
          },
        },
        [
          _c("template", { slot: "left" }, [
            _c("div", { staticClass: "main-left-tree-wrapper" }, [
              _c(
                "div",
                { staticClass: "main-left-tree-content" },
                [
                  _c("el-tree", {
                    ref: "formTreeRef",
                    staticClass: "main-left-tree-instance",
                    attrs: {
                      data: _vm.formTreeData,
                      "default-expand-all": "",
                      "expand-on-click-node": false,
                      "highlight-current": "",
                      "node-key": "id",
                    },
                    on: { "node-click": _vm.clickTreeNode },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var data = ref.data
                          return [
                            _c(
                              "span",
                              { staticClass: "custom-tree-node tree-span" },
                              [
                                _c(
                                  "span",
                                  { staticStyle: { "margin-left": "5px" } },
                                  [_vm._v(_vm._s(_vm.$t(data.name)))]
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c("template", { slot: "right" }, [
            _c("div", { staticClass: "page-search-bar" }, [
              _c(
                "div",
                { staticStyle: { width: "200px" } },
                [
                  _c("el-input", {
                    attrs: {
                      "suffix-icon": "el-icon-search",
                      clearable: "",
                      placeholder: _vm.$t("lang_qsrnr"),
                    },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.handleSearch($event)
                      },
                    },
                    model: {
                      value: _vm.formSearchModel.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.formSearchModel, "name", $$v)
                      },
                      expression: "formSearchModel.name",
                    },
                  }),
                ],
                1
              ),
              _vm.activeName == "first"
                ? _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            icon: "el-icon-plus",
                            disabled:
                              _vm.catalogueId == "" || _vm.catalogueId == "-1"
                                ? true
                                : false,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.addFile()
                            },
                          },
                        },
                        [_vm._v("新建")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
            _c(
              "div",
              {
                staticStyle: {
                  background: "#fff",
                  height: "100%",
                  padding: "0 10px 0 10px",
                },
              },
              [
                _c(
                  "el-table",
                  {
                    staticClass: "page-pagetable",
                    attrs: {
                      border: "",
                      data: _vm.tableListData,
                      "empty-text": _vm.$t("lang_no_data"),
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        label: _vm.$t("lang_file_name"),
                        align: "center",
                        prop: "objectName",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "process-inst-title",
                                  on: {
                                    click: function ($event) {
                                      return _vm.jumpEdit(scope.row)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(scope.row.objectName) + " "
                                  ),
                                ]
                              ),
                              !scope.row.readState
                                ? _c("img", {
                                    staticClass: "img",
                                    attrs: {
                                      src: require("@/assets/image/door/new_icon_blue_32.png"),
                                      alt: "",
                                    },
                                  })
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: _vm.$t("lang_release_time"),
                        align: "center",
                        prop: "intoDate",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.getSpeTime(scope.row.intoDate)) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: _vm.$t("lang_operation"),
                        align: "center",
                        width: "100",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.scope == "1"
                                ? _c(
                                    "el-button",
                                    {
                                      staticStyle: { color: "#007aff" },
                                      attrs: { type: "text" },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("lang_see")) + " ")]
                                  )
                                : _vm._e(),
                              scope.row.scope == "2"
                                ? _c(
                                    "el-button",
                                    {
                                      staticStyle: { color: "#007aff" },
                                      attrs: { type: "text" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("lang_download")) + " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              scope.row.scope == "4"
                                ? _c(
                                    "el-button",
                                    {
                                      staticStyle: { color: "#007aff" },
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.editFile(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("lang_edit")))]
                                  )
                                : _vm._e(),
                              scope.row.scope == "4"
                                ? _c(
                                    "el-button",
                                    {
                                      staticStyle: { color: "#007aff" },
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.removeFile(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("lang_delete")))]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _c("el-pagination", {
                  staticClass: "page-pager",
                  attrs: {
                    background: "",
                    "current-page": _vm.currentPage,
                    "page-sizes": [10, 20, 30, 40],
                    "page-size": _vm.pageSize,
                    layout: "total, sizes, prev, pager, next, jumper",
                    total: _vm.total,
                  },
                  on: {
                    "size-change": _vm.changePageSize,
                    "current-change": _vm.changePage,
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _vm.isShowAddEdit
        ? _c("DocumentForm", {
            attrs: { displayName: _vm.displayName, visible: _vm.isShowAddEdit },
            on: {
              "update:visible": function ($event) {
                _vm.isShowAddEdit = $event
              },
              changeParentDialogName: _vm.changeParentDialogName,
              success: _vm.closeDiaolog,
            },
          })
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.formDialogVisible,
            width: "90%",
            top: "2vh",
            "append-to-body": "",
            "close-on-click-modal": false,
            "custom-class": "cus-data-viewer",
          },
          on: {
            "update:visible": function ($event) {
              _vm.formDialogVisible = $event
            },
            close: _vm.handleCloseDialog,
          },
        },
        [
          _c("template", { slot: "title" }, [
            _c("div", { staticClass: "my-viewer-title-img" }),
            _c("div", { staticClass: "my-viewer-title" }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.successCreatDocumentForm
                      ? _vm.$t("lang_new_document") +
                          "-" +
                          _vm.displayParentName
                      : _vm.$t("lang_edit_form_data") + "-" + _vm.displayName
                  )
                ),
              ]),
            ]),
          ]),
          _vm.formDialogVisible
            ? _c("form-viewer", {
                ref: "formViewerRef",
                attrs: { use: "data" },
              })
            : _vm._e(),
          _c(
            "template",
            { slot: "footer" },
            [
              _c("el-button", { on: { click: _vm.handleCloseDialog } }, [
                _vm._v(_vm._s(_vm.$t("lang_shut_down"))),
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleSave } },
                [_vm._v(_vm._s(_vm.$t("lang_determine_")))]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }